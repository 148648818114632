<template>
  <div class="products py-5" :class="products[product_id].image">

    <div class="container-fluid py-5" data-aos="fade-down" data-aos-duration="2000">
      
      <div class="row pb-5">
        <div class="col-6 mx-auto text-center">
          <p class="display-1 mb-1 text-c1"><i class="fad fa-cubes"></i></p>
          <h1 class="text-c1 mb-4"><strong>Productos</strong></h1>
        </div>
      </div>

      <div class="row pt-3 pb-5">
        <div class="container">
          
          <div class="container">
            <div class="row justify-content-center">

              <div class="col-md-3 mb-2">
                <b-button v-on:click="select_product(0)" :variant="(product_id == 0) ? 'c3' : 'c1'" class="px-3" block>
                  <h5 class="mb-0"><strong>Acero Inoxidable</strong></h5>
                </b-button>
              </div>
              
              <div class="col-md-3 mb-2">
                <b-button v-on:click="select_product(1)" :variant="(product_id == 1) ? 'c3' : 'c1'" class="px-3" block>
                  <h5 class="mb-0"><strong>Aluminio</strong></h5>
                </b-button>
              </div>

              <div class="col-md-3 mb-2">
                <b-button v-on:click="select_product(2)" :variant="(product_id == 2) ? 'c3' : 'c1'" class="px-3" block>
                  <h5 class="mb-0"><strong>Varios</strong></h5>
                </b-button>
              </div>

            </div>
          </div>

          <div class="container pt-5">
            <div class="row px-md-3">
              <div class="col-12 rounded px-md-4">

                <div class="row px-1" :class="custom_animation">
                  
                  <div class="col-lg-8 mb-3 text-center py-4 mx-auto">
                    
                    <h1 class="mb-2">{{ products[product_id].title }}</h1>
                    <h4 v-if="products[product_id].subtitle" class="d-none d-md-block mb-4 font-weight-bold">{{ products[product_id].subtitle }}</h4>
                    <h5 v-if="products[product_id].subtitle" class="d-block d-md-none mb-4 font-weight-bold">{{ products[product_id].subtitle }}</h5>
                    <p v-for="(item, key) in products[product_id].list" :key="key" class="h4 mb-2">
                      <strong>
                        <span class="small text-muted pr-2"><i class="fad fa-cube"></i></span>
                        {{ item }}
                      </strong>
                    </p>
                    
                    <hr class="d-sm-block d-md-none">
                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      custom_animation: '',
      product_id: '',
      products: ''
    }
  },
  methods: {
    select_product(id) {
      this.custom_animation = ''
      
      setTimeout(() => {
        this.product_id = id
        this.custom_animation = 'animate__animated animate__fadeIn'  
      }, 10);
    }
  },
  created() {
    this.custom_animation = 'animate__animated animate__fadeIn'
    this.product_id = 0
    this.products = {
      0: {
        title: 'Acero Inoxidable',
        subtitle: "🔹T-303 🔹T-304 🔹T-304L 🔹T-310 🔹T-316 🔹T-316L 🔹T-416 🔹T-430",
        image: 'acero-inoxidable',
        list: [
          'Laminas',
          'Laminas perforadas',
          'Placas',
          'Placas antiderrapantes',
          'Soleras',
          'Ángulos',
          'Barras (Redonda, Cuadrada, Hexagonal)',
          'Tubo en cédula',
          'Tubo ornamental (Redondo, Cuadrado)',
          'Tubo sanitario',
          'Conexiones',
          'Tornillería',
          'Mallas y alambre'
        ]
      },
      1: {
        title: 'Aluminio',
        subtitle: "",
        image: 'aluminio',
        list: [
          'Laminas',
          'Laminas Antiderrapantes',
          'Barras',
          'Soleras',
          'Ángulos',
          'Tubos'
        ]
      },
      2: {
        title: 'Varios',
        subtitle: "",
        image: 'varios',
        list: [
          'Latón',
          'Bronce',
          'Cobre',
          'Nylon',
          'Acero Negro'
        ]
      },
    }
  }
}
</script>

<style scoped>
img {
  height: 100%;
}

.acero-inoxidable {
  background-image: url('../assets/material/acero-inoxidable.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.aluminio {
  background-image: url('../assets/material/aluminio.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.varios {
  background-image: url('../assets/material/varios.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>